body {
  height: 100vh;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:rgb(71, 61, 139);
  color:white;
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Montserrat', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main{
  height: 100vh;
  padding: 0px;
}

.title-header{
  padding-top: 10px;
}

.footer{
  margin: 0px !important;
  padding: 0px !important;
  color: white;
  background-color: grey;
}

.material-icons{
  padding-left: 3px;
  padding-right: 3px;
}

.content {
  height: calc(100vh - 5rem);
}

hr{
  margin: 1px;
}

.green{
  color: #95CD41;
}

.red{
  color: #EE4B2B;
}

.gray{
  color:darkgrey;
}